import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Directions } from './Directions'

export interface Props {
  address?: string
  directions?: string[]
  googleMapsURL?: string
  image?: ImageProps
  languageCode: string
  siteName?: string
}

export const Map = memo(function Map({
  address,
  directions,
  googleMapsURL,
  image,
  languageCode,
  siteName,
}: Props) {
  return (
    <Container>
      <Background>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </Background>

      <Wrapper>
        {siteName ? <SiteName>{siteName}</SiteName> : null}
        {address ? <Address>{address}</Address> : null}
        {googleMapsURL ? (
          <CTA
            label={useVocabularyData('open-the-map', languageCode)}
            URL={googleMapsURL}
            rel="noreferrer"
            target="_blank"
            variant="invert"
          />
        ) : null}
      </Wrapper>

      {directions ? (
        <Directions languageCode={languageCode} list={directions} />
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin: 15.3125rem auto 12.5rem;
  padding-bottom: 7.25rem;
  position: relative;

  @media (max-width: 1023px) {
    margin: 5.625rem auto 7.5rem;
  }

  @media (max-width: 767px) {
    margin-bottom: 5.625rem;
    padding-bottom: 5rem;
  }
`

const Background = styled.div`
  width: 100%;
  height: 55.6vh;
  max-height: 31.25rem;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Wrapper = styled.div`
  max-width: 51.5rem;
  background: ${({ theme }) => theme.colors.variants.primaryLight3};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: -3.75rem auto 0;
  padding: 3.75rem 6.667vw;
  position: relative;
  text-align: center;
  z-index: 2;

  @media (max-width: 1023px) {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 3rem 1.875rem;
  }
`

const SiteName = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.4375rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
`

const Address = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
`

const CTA = styled(Button)`
  margin-top: 2.5rem;
`
